:root {
    --primary: white;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: rgba(255, 255, 255, 0.85);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border: 2.5px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}

.arrow {
    transition: all 0.3s ease-out;
}

.btn--large.btn--primary:hover .arrow {
    color: rgb(var(--accent));
}