.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    /* height: 80px; */
    /* padding: 16px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;

    min-height: 32px;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 80px; */
    max-width: 1500px;
    height: 80px;
  }
  
  .a {
    display: flex;
  }

  .navbar-logo {
    color: #fff;
    justify-self: start;
    display: flex;
    align-items: center;
    margin: 0px 16px;
    
    /* padding: 16px; */
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 0px solid #fff;
    
    transition: all 0.2s ease-out;
  }
  
  .nav-links:hover {
    color: rgba(var(--accent), 1);
    border-bottom: 4px solid #fff;

    transition: all 0.1s ease-out;
  }

  .accent {
    border-bottom: 4px solid rgba(var(--accent), 1);
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
    color: red;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;      
      backdrop-filter: blur(3px);

      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222a6;
      left: 0;
      opacity: 1;
      z-index: 1;
      
      transition: all 0.75s ease;
    }
  
    .nav-links {
      background: #242222a6;
      text-align: center;
      padding: 2rem;
      width: 100%;
      height: 100%;
      display: table;

      /* border: 3px solid rgb(39, 39, 39); */

      border-bottom: 0px solid #fff;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;

      border-bottom: 0px solid #fff;
    }

    .accent {
      color: rgba(var(--accent), 1);
    }
  
    .navbar-logo {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(25%, 35%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      
      filter: drop-shadow(0px 0px 0px rgba(255,255,255,0));
      transition: 1.2s;
    }

    .menu-icon:hover {
      filter: drop-shadow(0px 0px 12px rgba(255,255,255,1));
      transition: 0.1s;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;

      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;

      transition: 250ms;
    }
  }