video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container-wrapper {
    /* filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 50%)); */
}

.hero-container {
    
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.3);
    /* box-shadow: 0 0 0 1000px rgba(0,0,0,0.2); */
    /* filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 50%)); */
}

.hero-content {
    padding-top: 10vh;
    padding-bottom: 10vh;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;

    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 50%));
}

.background {
    object-fit: cover;
    position: fixed;
    z-index: -1;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    height: auto;

    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    filter: blur(0.78px);

    animation-name: from-blur;
    animation-duration: 2.5s;
}

.hero-content img {
    /* margin-top: -100px; */
    width: 85%;
    max-width: 830px;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 70%));
    animation-name: from-transparent;
    animation-duration: 1.2s;
}

@keyframes from-blur {
    from{filter: blur(5px);}
    to{filter: blur(0.78px);}
}

@keyframes from-transparent {
    from{opacity: 0;}
    to{opacity: 1;}
}

.hero-content > h1 {
    color: white;
    font-size: 100px;
    margin-top: -100px;
}

.hero-content > p {
    margin-top: 8px;
    color: white;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

.contact-info {
    color: white;
}

.contact-info-wrapper {
    padding-top: 1.5rem;
    /* align-content: center; */
}

.contact-info li {
    font-size: 18px;

    float: left;
    display: block;
    color: white;
    padding: 0px 16px;
    text-decoration: none;

    filter: drop-shadow(0px 0px 2px black);
}

.contact-info li:last-child {
    float:none;
    text-align: center;
    padding-top: 30px;
}

.contact-info i {
    color: rgb(var(--accent));
}

.contact-info a {
    filter: drop-shadow(0px 0px 2px black);
    /* color: rgb(var(--accent)); */
}

@media screen and (max-width: 960px) {
    .hero-content > h1 {
        font-size: 70px;
        margin-top: -150px;
    }

    .background {
        min-height: 100%;
        width: 100%;
        /* height: auto; */
    }
}

@media screen and (max-width: 768px) {
    .hero-content > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-content > p {
        font-size: 30px;
    }

    .contact-info li:last-child {
        padding-top: 0px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    .background {
        min-height: 100%;
        width: 100%;
    }

    .contact-info-wrapper > contact-info li {
    
        float: none;
    }
}