.cards {
  /* padding: 4rem; */
  background: #fff;
}

.cards > h1 {
  text-align: center;

  border-top: var(--borderThickness) solid var(--accentColor);
  max-width: var(--maxWidth);

  padding-top: 1rem;

  margin-left: auto;
  margin-right: auto;
}

.cards__container {
  display: flex;
  flex-flow: column;
  /* align-items: center; */
  max-width: var(--maxWidth);
  width: 100%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 1rem 0 45px;
}

.cards__items {
  margin-bottom: 24px;

  animation-name: from-bottom, from-transparent;
  animation-duration: 1.5s;

  /* min-width: 300px; */
}

.cards__item {
  background-color: white;
  display: flex;
  flex: 1;
  margin: 0 0.8rem;
  border-radius: 10px;
  /* min-width: 25%; */

  transition: 0.2s;
}

/* Cards item scale on hover */
/* .cards__item:hover {
  transform: scale(1.05);

  transition: 0.2s;

  z-index: 10;
} */

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  /* 56, 125, 255 */
  box-shadow: 0 6px 20px rgba(59, 184, 235, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(59, 184, 235, 0.017));
  filter: drop-shadow(0 6px 20px rgba(59, 184, 235, 0.017));

  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

/* .cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: -45px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
} */

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  /* max-width: 100%; */
  height: 100%;
  /* max-height: 100%; */
  object-fit: cover;
  transition: all 0.2s linear;
}


/* .cards__item__img span {
  right: 10px;
  top: 10px;
} */

/* Image scale on hover */
/* .cards__item__img:hover {
  transform: scale(1.1);
} */

.cards__item__info {
  padding: 10px 10px 30px;
}

.cards__item__title {
  color: #252e48;
  font-size: 20px;
  line-height: 24px;
}

.cards__item__text {
  color: #626775;
  font-size: 16px;
  padding-top: 10px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    /* width: 84%; */
  }

  
  
}

@media only screen and (min-width: 750px) {
  .cards__items {
    display: flex;
  }

  .cards__item {
    /* margin: 0 0.8rem; */
    /* width: 100%; */
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
    /* margin: 0 1rem; */
  }

  .cards__container {
    width: 95%;
  }
}