* {
  /* Global variables */
  --accent: 59, 184, 235;
  --accentColor: rgb(var(--accent));
  --maxWidth: 1220px;
  --borderThickness: 1px;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.page {
  transition: 0.5s;

  color: #777777;
}

/* Logo */
.logo {
  cursor: pointer;
  transition: ease-out 0.25s;
}

.logo:hover {
  transition: ease-out 0.1s;
  filter: drop-shadow(0px 0px 4px rgba(var(--accent), 1));
}

/* Pages */
.home,
.factuur,
.portfolio,
.contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.factuur {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.portfolio {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* Underline effect */
.un {
  display: inline-block;
}

.un::after {
content: '';
width: 0px;
height: 2.1px;
display: block;
background: #242424;
transition: 0.3s;
}

.un:hover::after {
width: 100%;
}

/* Generic Text */
h1 {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 25%));
}

hr.textUnderLine {
  border: 2px solid var(--accentColor);
  border-radius: 3px;

  max-width: 6rem;

  /* margin-left: auto; */
  margin-right: auto;

  margin-bottom: 0.5rem;
}


/* Animations */
@keyframes from-left {
  from{transform:translateX(-40%);}
  to{transform:translateX(0);}
}

/* Animations */
@keyframes from-bottom {
  from{transform:translateY(40%);}
  to{transform:translateX(0);}
}


@keyframes from-transparent {
  from{opacity: 0;}
  to{opacity: 1;}
}

.text_underline {

  font-style: normal;

  text-decoration: underline;
  text-decoration-thickness: 2.5px;
  -webkit-text-decoration-color: rgb(var(--accent)); /* Safari */  
  text-decoration-color: rgb(var(--accent));
}

.text_bold {

  color: black;
  font-style:normal;
}