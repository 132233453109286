.company-info {
    padding: 1rem 4rem 4rem 4rem;
    
    background: #ffffff;
    /* align-content: center; */
}

.company-info-content {

    max-width: var(--maxWidth);
    margin-left: auto;
    margin-right: auto;

    /* border-top: var(--borderThickness) solid var(--accentColor); */
}

.company-info-content > h1 {
    /* text-align: center; */

    padding-bottom: 0.5rem;
}

.company-info-story {
    animation-name: from-left, from-transparent;
    animation-duration: 1.4s;
}

.company-info-story > p {
    
    font-size: larger;

    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 25%));
}



@keyframes from-blur {
    from{filter: blur(5px);}
    to{filter: blur(0);}
}

@media screen and (max-width: 960px) {
    .company-info{
        padding: 1rem 2rem 2rem 2rem;
    }

    .hero-content > h1 {
        font-size: 70px;
        margin-top: -150px;
    }

    .background {
        min-height: 100%;
        width: 100%;
        /* height: auto; */
    }
}

/* Phone */
@media screen and (max-width: 768px) {

    .hero-content > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-content > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    .background {
        min-height: 100%;
        width: 100%;
    }

    .contact-info li {
    
        float: none;
    }
}